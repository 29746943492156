/* Vinod Alphonse P K –1002057501 , Sai Subhash Yalamadala –1002031729
 Ravi Ailani –1002026213, Yashasvi Matta –1002091134
Adithya Venkatesh –1002032198 */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: hsla(0, 0%, 0%, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  font-size: 4rem;
  animation: pulse 0.6s linear 0.3s infinite normal forwards;
}
@keyframes pulse {
  0% {
    color: hsla(0, 0%, 100%, 1);
  }
  50% {
    color: hsla(0, 0%, 100%, 0.1);
  }
  100% {
    color: hsla(0, 0%, 100%, 1);
  }
}

.home-img {
  vertical-align: middle;
  height: 42.5rem;
  width: 100%;
  margin: 0 auto;
}

.text {
  color: #f2f2f2;
  font-size: 30px;
  padding: 20px 15px;
  position: absolute;
  left: 20px;
  top: 20%;
  width: 35%;
  background: rgba(45, 43, 43, 0.7);
  text-align: left;
}

.about-section {
  height: 42.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #7a8396;
  color: white;
  text-align: center;
  margin: 0px auto 0px auto;
}

.about-header {
  margin-bottom: 20px;
  font-size: 40px;
}

.about-content {
  text-align: center;
  font-size: 23px;
  margin: 40px;
}

.service-hover:hover {
  opacity: 0.5;
}

/* Styling the area of the slides */

#slideshow {
  overflow: hidden;
  height: 640px;
  width: 728px;
  margin: 40px auto 0 auto;
}

/* Style each of the sides
with a fixed width and height */

.slide {
  float: left;
  height: 510px;
  width: 728px;
}

/* Add animation to the slides */

.slide-wrapper {
  /* Calculate the total width on the
basis of number of slides */
  width: calc(728px * 4);

  /* Specify the animation with the
duration and speed */
  animation: slide 10s ease infinite;
}

/* Set the background color
of each of the slides */

/* Define the animation
for the slideshow */

@keyframes slide {
  /* Calculate the margin-left for
each of the slides */
  20% {
    margin-left: 0px;
  }
  40% {
    margin-left: calc(-728px * 1);
  }
  60% {
    margin-left: calc(-728px * 2);
  }
  80% {
    margin-left: calc(-728px * 3);
  }
}


/* CSS */
@media (max-width: 640px) {
  .sidebar-menu {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    background-color: #fff;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar-menu li {
    display: block;
    margin: 0;
    text-align: center;
  }

  .sidebar-menu li a {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
}